import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { collapseDrawerWidth, drawerWidth } from 'store/constant';
import { setMenu } from 'store/features/customizationSlice';
import { useRef, useEffect, useState } from 'react';

const DrawerStyled = styled('div')(({ theme, open }) => ({
  background: 'white',
  width: open ? drawerWidth : collapseDrawerWidth,
  position: 'sticky',
  top: 80,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  [theme.breakpoints.down('lg')]: {
    position: 'fixed',
    top: 0,
    left: open ? 0 : -drawerWidth,
    bottom: 0,
    zIndex: 2000,
    width: drawerWidth,
    boxShadow: theme.shadows[6],
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  }
}));

const DrawerOverlay = styled('div')(({ theme, open }) => ({
  [theme.breakpoints.down('lg')]: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(9, 0, 0, 0.7)',
    zIndex: 1900,
    opacity: open ? 1 : 0,
    visibility: open ? 'visible' : 'hidden',
    transition: theme.transitions.create(['opacity', 'visibility'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  }
}));

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  // Handle touch events in MobileView to prevent background scrolling
  const mobileScrollRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleTouchStart = (e) => {
      mobileScrollRef.current.startY = e.touches[0].clientY;
      mobileScrollRef.current.startScrollTop = mobileScrollRef.current.scrollTop;
      setIsScrolling(false); // Reset the scroll flag
    };

    const handleTouchMove = (e) => {
      const currentY = e.touches[0].clientY;
      const scrollTop = mobileScrollRef.current.scrollTop;
      const scrollHeight = mobileScrollRef.current.scrollHeight;
      const offsetHeight = mobileScrollRef.current.offsetHeight;

      const isScrollingUp = currentY > mobileScrollRef.current.startY;
      const isScrollingDown = currentY < mobileScrollRef.current.startY;

      // Mark scrolling state only if there is noticeable movement
      if (Math.abs(currentY - mobileScrollRef.current.startY) > 5) {
        setIsScrolling(true);
      }

      // Determine if user is at the top or bottom of the sidebar scroll area
      const isAtTop = scrollTop === 0 && isScrollingUp;
      const isAtBottom = scrollTop + offsetHeight >= scrollHeight && isScrollingDown;

      // Prevent scrolling the background page when at the edges of the sidebar
      if (isAtTop || isAtBottom) {
        e.preventDefault();
      }
    };

    const handleTouchEnd = () => {
      setTimeout(() => setIsScrolling(false), 100); // Allow quick tap after scrolling
    };

    const scrollEl = mobileScrollRef.current;
    if (scrollEl) {
      scrollEl.addEventListener('touchstart', handleTouchStart);
      scrollEl.addEventListener('touchmove', handleTouchMove);
      scrollEl.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      if (scrollEl) {
        scrollEl.removeEventListener('touchstart', handleTouchStart);
        scrollEl.removeEventListener('touchmove', handleTouchMove);
        scrollEl.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, []);

  const handleClick = (e) => {
    if (isScrolling) {
      e.preventDefault(); // Prevent click action during scroll
    }
  };

  const drawer = (
    <>
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
            paddingLeft: '16px',
            paddingRight: '16px'
          }}
        >
          <MenuList />
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box
          ref={mobileScrollRef}
          onClick={handleClick} // Only allow clicks when not scrolling
          sx={{
            px: 2,
            height: '100vh',
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch' // For smooth scrolling on iOS devices
          }}
        >
          <MenuList />
        </Box>
      </MobileView>
    </>
  );

  return (
    <>
      <div>
        <DrawerStyled open={drawerOpen}>{drawer}</DrawerStyled>
      </div>
      <DrawerOverlay open={drawerOpen} onClick={() => dispatch(setMenu({ opened: false }))} />
    </>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool
};

export default Sidebar;
